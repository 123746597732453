import React from 'react';

const Attorneys = () => {
    return (
    <section className="attorneys black">
        <div className="container">
          <div className="row">
            <div className="col s12">
        
                <div className="row">
                    <div className="col s12">
                      <h3 className="brand-light uppercase mb0">Attorneys</h3><br />
                      <p className="white-text left-align hide">Emolo & Collini is an experienced law firm representing clients</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col s12 m4">
                        <img className="responsive-img" alt="Mr. John C Emolo Esq." src="emolo.png" />
                    </div>
                    <div className="col s12 m8">
                    <h3 className="brand-light uppercase m0">Mr. John C Emolo Esq.</h3><br />
                        <p className="white-text left-align mt0">Mr. John C. Emolo, Esq., handles workers compensation matters, bankruptcy litigation, real estate, and Appellate matters and has been doing so for the past (30) years. Mr. Emolo is also an assistant corporate counsel with the City of Paterson concerning the Tax Appeals that are filed against it. He has been in this position for the past (30 Years).</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col s12 m4">
                        <img className="responsive-img" alt="Mr. Joseph Collini Esq." src="collini.png" />
                    </div>
                    <div className="col s12 m8">
                    <h3 className="brand-light uppercase m0">Mr. Joseph Collini Esq.</h3><br />
                        <p className="white-text left-align mt0">Mr. John C. Emolo, Esq., handles workers compensation matters, bankruptcy litigation, real estate, and Appellate matters and has been doing so for the past (30) years. Mr. Emolo is also an assistant corporate counsel with the City of Paterson concerning the Tax Appeals that are filed against it. He has been in this position for the past (30 Years).</p>
                    </div>
                </div>   

            </div>
          </div>
        </div>
    </section>
    )
}
export default Attorneys;
