import React, {Component} from 'react';
import $ from 'jquery';
import 'materialize-css/dist/js/materialize.js';
// import 'materialize-css/js/parallax.js';
// let $ = require('jquery');

class Parallax extends Component {

    componentDidMount() {

      $(document).ready(function(){
        // $('.parallax').parallax();
      });
    }

    render() {

        return (
         
          <div id="attorney-none" className="parallax-section row mb0">
            <div className="col s12 p0">    
              <div className="parallax-container">
                <div className="parallax"></div>
              </div>
            </div>
          </div>
        
        );
    }
}

export default Parallax;