import React from 'react';

const About = () => {
  return (
    <section id="attorney-none" className="about white valign-wrapper">
        <div className="container">
          <div className="row">
            <div className="col s12">
            <a name="about"></a>
              <div className="card brand">
                <div className="card-content white-text left-align">
                  <div className="row">
                    <div className="col s12 offset-m1 m10 offset-l2 l8">
                      <h3 className="uppercase">About Us</h3><br />
                      <p className="left-align">Emolo & Collini is an experienced law firm representing clients injured or killed in cases involving hospitals, nursing homes, doctors, nurses, and other healthcare facilities across the U.S. 
                      <br /><br />
                      Our personal injury firm is a leading team of professionals with a strong reputation for meeting client needs. Our personalized approach to each case allows us to develop a comprehensive strategy for every one of our clients. We begin with a consultation to discuss the facts of your case and the influence of your injuries. When hiring an experienced firm of New Jersey personal injury attorneys, it can help to understand the typical procedures and processes of your case. This will help prepare you for what to expect. You need experience you can trust.
                      <br /><br />
                      In a typical case, you may need to interact with an insurance carrier and representatives for the other party. This can be nerve-wracking and confusing, which is why working with a talented team of attorneys from the outset can be so beneficial. You need someone on your side to fight for your rights. Many individuals entering a personal injury case are not familiar with the law, their rights, and their obligations. Meeting with a personal injury attorney early on can ease your fears and instill you with confidence.
                      <br /><br />
                      As an accident victim, it doesn’t take long for medical bills to pile up. It’s likely that you have missed time at work and that you may no longer be able to enjoy daily activities the way that you could before. We understand the many ramifications of a serious accident for your life and that’s why we are so committed to serving individuals who have been impacted in a major way.
                      <br /><br />When seeking out an appropriate New Jersey personal injury attorney, experience talks. Our reputation precedes itself with numerous cases in many fields of personal injury. We fight for your rights so that you can focus on recovery.
                      </p>
                    </div>
                  </div>
                </div>          
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}
export default About;
