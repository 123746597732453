import React from 'react';

const Services = () => {
  return (
    <section id="service-none" className="service-section white valign-wrapper">
        <div className="container">
          <div className="row">
          <a name="services"></a>
            <div className="col s12 left-align">
              <h3 className="brand-light uppercase mb0">Services</h3>
            </div>
          </div>

          <div className="row">
            <div className="col s12 left-align">
              <p className="left-align">When you’re looking for an attorney to represent you in a personal injury case, the numbers talk. There are numerous factors you should consider when hiring legal representation, but one of the most important is the background of your attorneys and whether they have been able to achieve results in the past. If you have been injured in a personal injury accident, you need to consult with New Jersey personal injury attorneys sooner rather than later. Our firm is fully equipped and dedicated to serving injury victims in the fields of:</p>
            </div>
          </div>
        
          <div className="services row services">
            <div className="col s12 m4 center">
              <div className="slip card brand">
                <div className="card-content white-text left-align">

                  <div className="service-title valign-wrapper">
                    <div className="row mb0">
                      <div className="col s12 center-align">
                        <p className="brand-text--dark center-align m0">Slip & Falls</p>
                      </div>
                    </div>
                  </div>

                </div>          
              </div>
            </div>

            <div className="col s12 m4 center">
              <div className="hospital card brand">
                <div className="card-content white-text left-align">

                  <div className="service-title valign-wrapper">
                    <div className="row mb0">
                      <div className="col s12 center-align">
                        <p className="brand-text--dark center-align m0">Hospital Negligence</p>
                      </div>
                    </div>
                  </div>

                </div>          
              </div>
            </div>

            <div className="col s12 m4 center">
              <div className="injuries card brand">
                <div className="card-content white-text left-align">
                   <div className="service-title valign-wrapper">
                    <div className="row mb0">
                      <div className="col s12 center-align">
                        <p className="brand-text--dark center-align m0">Legal Malpractice</p>
                      </div>
                    </div>
                  </div>
                </div>          
              </div>
            </div>

          </div>
          <div className="services row services">

            <div className="col s12 m4 center">
              <div className="mva card brand">
                <div className="card-content white-text left-align">

                  <div className="service-title valign-wrapper">
                    <div className="row mb0">
                      <div className="col s12 center-align">
                        <p className="brand-text--dark center-align m0">Motor Vehicle Accidents</p>
                      </div>
                    </div>
                  </div>

                </div>          
              </div>
            </div>

            <div className="col s12 m4 center">
              <div className="liability card brand">
                <div className="card-content white-text left-align">

                  <div className="service-title valign-wrapper">
                    <div className="row mb0">
                      <div className="col s12 center-align">
                        <p className="brand-text--dark center-align m0">Premises Liability</p>
                      </div>
                    </div>
                  </div>

                </div>          
              </div>
            </div>

            <div className="col s12 m4 center">
              <div className="medication card brand">
                <div className="card-content white-text left-align">

                  <div className="service-title valign-wrapper">
                    <div className="row mb0">
                      <div className="col s12 center-align">
                        <p className="brand-text--dark center-align m0">Medical Malpractice</p>
                      </div>
                    </div>
                  </div>

                </div>          
              </div>
            </div>



          </div>
        </div>
    </section>
  )
}
export default Services;
