import React from 'react';

const Header = () => {
  return (

    <section className="header black">
      <div className="container">
        <div className="row">
            <div className="col s12 left-align">
              <h1 className="brand-text--light uppercase mb0 mt0">Emolo & Collini <br /> Counselors at Law</h1>    
            </div>
            <div className="col s12 m9 left-align">
            	<p className="white-text">Millions recovered for injury victims. We fight for your rights so that you can focus on recovery.</p>
            </div>
        </div>
        <div className="row">
          <div className="col s12 m6 l4 left-align">
            <a href="tel:973-742-6463" className="waves-effect waves-light btn brand uppercase">Book A Free Consultation</a>
          </div>
          <div className="col s12 m6 l5 left-align">
            <a href="#services" className="waves-effect waves-light btn transparent uppercase">View Our Services</a>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Header;
