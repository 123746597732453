import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import $ from 'jquery';
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css'
import Header from './Header';
import Attorneys from './Attorneys';
import { FaBars } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
window.jQuery = window.$ = $;

// require('materialize-css');



class Nav extends Component {

    // constructor () {
    //     super();
    // }
    componentDidMount() {
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems);
        });

        document.getElementById('mobile-link').addEventListener('click',function(e){
            document.getElementById('mobile-demo').style.display = 'none';
            console.log('true');
        });

        $(document).ready(function(){

            function hideFooter(path){
                path = window.location.pathname;

                if (path === '/attorneys') {
                    console.log('true');
                    document.getElementById("service-none").style.display = "none";
                    document.getElementById("attorney-none").style.display = "none";
                }
            }
            return hideFooter();

            $(".sidenav").click(function(){
                $(".sidenav a").hide();
                console.log('true');
            });

        });
    }


    render() {

        return (

            <Router className="">
                <div className="black z-depth-0">
                    <nav className="black z-depth-0" id="display-none">
                        <div className="nav-wrapper transparent">
                            <Link className="brand-logo hide-on-large-only" to="/"><img className="logo responsive-img" alt="Emolo & Collini" src="logo.png"/></Link>
                            <ul className="left hide-on-med-and-down uppercase">
                                <li><a href="#about">About Us</a></li>
                                <li><a href="#services">Our Services</a></li>
                                <li className="hide"><a className="hide" href="testimonials">Testimonials</a></li>  
                                <li><Link className="brand-logo-desktop" to="/"><img className="logo responsive-img" alt="Emolo & Collini" src="logo.png"/></Link></li>     
                                <li className="hide">
                                    <Link to="/attorneys">Attorneys</Link>
                                </li>
                                <li><a href="#contact">Contact</a></li>
                                <li><a href="tel:973-742-6463"><FaPhone />&nbsp; 973.742.6463</a></li>                  
                            </ul>

                            <a href="#" data-target="mobile-demo" className="sidenav-trigger right"><i className="material-icons right hide"><i className="material-icons white-text"><FaBars /></i></i></a>
                            <ul className="sidenav uppercase black" id="mobile-demo">
                                <li id="mobile-link"><a href="#about">About Us</a></li>
                                <li id="mobile-link"><a href="#services">Our Services</a></li>
                                <li className="hide"><a href="testimonials">Testimonials</a></li>  
                                <li className="hide">
                                    <Link to="/attorneys">Attorneys</Link>
                                </li>
                                <li><a href="#contact">Contact</a></li>
                                <li><a href="tel:973-742-6463">Call 973.742.6463</a></li>                  
                            </ul>
                            
                        </div>
                    </nav>

                    <Route exact path="/" component={Header} />
                    <Route path="/attorneys" component={Attorneys} />
                </div>
            </Router>
        );
    }
}

export default Nav;