import React from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { FaFax } from 'react-icons/fa';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => {
  return (
 
    <footer className="page-footer black">
        <div className="container">
          <div className="row">
            <div className="col s12 center">
              <img className="logo responsive-img" alt="Emolo & Collini" src="logo.png"/>
            </div>
          </div>

          <div className="row">
            <div className="col s12 m4 center brand-border">
            <a name="contact"></a>
              <h3 className="brand-light uppercase">Contact</h3>
              <a className="white-text p" href="mailto:Email: jceemcolaw@optonline.net"><i className="material-icons brand-light"><FaEnvelope /></i>&nbsp; emcolaw@optonline.net</a><br />
              <a className="white-text p" href="tel:973-742-6463"><i className="material-icons brand-light"><FaPhone /></i>&nbsp; 973.742.6463</a><br />
              <a className="white-text p" href="tel:973-742-6463"><i className="material-icons brand-light"><FaFax /></i>&nbsp; 973.742.0386<br /><br /></a>
              
            </div>
            <div className="col s12 m4 center brand-border">
              <h3 className="brand-light uppercase">Location</h3>
              <p className="m0 hide">Emolo & Collini Law Offices</p>
              <p className="m0">375 Broadway, 2nd Floor</p>
              <p className="m0">Paterson, NJ 07501</p>
              <a className="white-text p" href="https://goo.gl/maps/EUzAeKd3iWH2" target="_blank"><i className="material-icons brand-light"><FaMapMarkerAlt /></i>&nbsp; Get Directions<br /><br /></a>
            </div>
            <div className="col s12 m4 center brand-border">
              <h3 className="brand-light uppercase">OPENING HOURS</h3>
              <p className="m0">Mon - Fri: 9am - 5pm</p>
              <p className="m0">Saturday: 9am - 1pm</p>
              <p className="m0">Sunday: Closed<br /><br /></p>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
          © 2018 Copyright Emolo & Collini Counselors at Law
          <a className="grey-text text-lighten-4 right hide" href="#!">More Links</a>
          </div>
        </div>
      </footer>
   
  )
}
export default Footer;
